import React, { forwardRef } from 'react'
import styled from 'styled-components'

import logo_white from '../img/UB_logotyp_cremevit_RGB.png'
import logo_grey from '../img/UB_logotyp_gra_RGB.png'

import { UIStore } from '../stores/UIStore'
import { observer } from 'mobx-react'
import { colors } from '../variables'
import { Container, Row, Col, Hidden, Visible } from 'react-grid-system'

import gasell from '../img/gasell.png'

import { useTranslation } from 'react-i18next'

export const Footer = observer(
  forwardRef((props, ref) => {
    const bg =
      UIStore.footerBg !== '' ? UIStore.footerBg : UIStore.backgroundShade
    const { t } = useTranslation()

    return (
      <FooterDivHolder bg={bg} ref={ref}>
        <StyledContainer>
          <Row>
            <Col sm={5}>
              <FooterLeft>
                <h1
                  style={{
                    color: bg === colors.green ? colors.orange : colors.green,
                  }}
                >
                  {t('Ta kontakt med oss idag')}
                </h1>
                <Hidden xs>
                  <Logo
                    alt="Logo"
                    src={bg === colors.green ? logo_white : logo_grey}
                  />
                </Hidden>
              </FooterLeft>
            </Col>
            <Col sm={1} />
            <Col sm={6}>
              <FooterRight>
                <p>
                  {t('Vill du bli konsult?')}
                  <br />
                  <a href="mailto:konsult@utvecklarbolaget.se">
                    konsult@utvecklarbolaget.se
                  </a>
                </p>
                <p>
                  {t('Vill du bli kund?')}
                  <br />
                  <a href="mailto:kund@utvecklarbolaget.se">
                    kund@utvecklarbolaget.se
                  </a>
                </p>
                <span style={{ display: 'block', marginTop: 20 }}>
                  Tel. 08-703 25 88
                </span>
                <GasellHolder>
                  <img src={gasell} alt="Gasellvinnare" />
                </GasellHolder>
                Gasellvinnare 2019, 2020, 2021
              </FooterRight>
              <Visible xs>
                <Logo
                  alt="Logo"
                  src={bg === colors.green ? logo_white : logo_grey}
                />
              </Visible>
            </Col>
          </Row>
        </StyledContainer>
      </FooterDivHolder>
    )
  })
)

export default Footer

const FooterDivHolder = styled.div`
  background-color: ${({ bg }: { bg: string }) => bg};
  width: 100vw;
  font-size: 18px;
  line-height: 22px;

  & > * {
    color: ${({ bg }: { bg: string }) =>
      bg === colors.green ? colors.white : colors.green};
  }
`

const StyledContainer = styled(Container)`
  padding: 40px 25px 20px;

  @media (min-width: 769px) {
    padding: 80px 0 100px;
  }
`

const FooterLeft = styled.div`
  h1 {
    font-size: 38px;
    line-height: 42px;
    margin-bottom: 30px;

    @media (min-width: 769px) {
      font-size: 64px;
      line-height: 1.09;
      margin-bottom: 90px;
    }
  }
`

const FooterRight = styled.div`
  font-size: 17px;
  line-height: 28px;
  margin-left: 0;

  @media (min-width: 769px) {
    font-size: 22px;
    line-height: 1.55;
    margin-left: 60px;
  }

  p,
  a {
    font-family: 'Miriam Libre';
  }
`

const GasellHolder = styled.div`
  display: flex;
  margin: 20px 0 8px;
  flex-direction: row;
  column-gap: 20px;

  img {
    width: 180px;

    @media (min-width: 1280px) {
      width: 300px;
      column-gap: 20px;
    }
  }
`

const Logo = styled.img`
  width: 80%;
  margin-top: 40px;

  @media (min-width: 769px) {
    width: 320px;
  }
`
