import styled from 'styled-components'
import React from 'react'
import { Link } from 'react-router-dom'

import Menu from './Menu'
import logo_white from '../img/UB_logotyp_2rader_cremevit_RGB.png'
import logo_grey from '../img/UB_logotyp_2rader_gra_RGB.png'
import { UIStore } from '../stores/UIStore'
import { colors } from '../variables'
import { Row, Container, Col, Visible, Hidden } from 'react-grid-system'
import { observer } from 'mobx-react'
import { MobileMenuItems } from './MenuItems'

const Header = observer(() => {
  return (
    <HeaderContainer
      style={{
        position: 'fixed',
        zIndex: 100,
        backgroundColor: UIStore.backgroundShade,
      }}
    >
      <Row
        style={{
          width: '100%',
          maxWidth: 1220,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Col md={4}>
          <LogoHolder>
            <HeaderLogo>
              <Link to="/">
                <img
                  alt="Logo"
                  src={
                    UIStore.backgroundShade === colors.green
                      ? logo_white
                      : logo_grey
                  }
                  style={{ cursor: 'pointer' }}
                />
              </Link>
            </HeaderLogo>
          </LogoHolder>
        </Col>
        <Col md={8}>
          <Hidden md>
            <DesktopMenuHolder>
              <Menu />
            </DesktopMenuHolder>
          </Hidden>
        </Col>
      </Row>
      <Visible xs sm md>
        <MobileMenuButton
          onClick={() => UIStore.toggleMobileMenu(!UIStore.mobileMenuOpen)}
          bg={
            UIStore.backgroundShade === colors.green
              ? colors.white
              : colors.green
          }
          open={UIStore.mobileMenuOpen}
        >
          <div className="first" />
          <div className="middle" />
          <div className="last" />
        </MobileMenuButton>
        <StyledMobileMenu open={UIStore.mobileMenuOpen}>
          <MobileMenuItems />
        </StyledMobileMenu>
      </Visible>
    </HeaderContainer>
  )
})

const HeaderContainer = styled(Container)`
  display: flex;
  position: relative;
  width: 100vw;
  justify-content: center;
  align-items: center;
  position: fixed;
  max-width: none !important;
  height: 70px;

  @media (min-width: 769px) {
    height: 90px;
  }
`

const LogoHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;

  @media (min-width: 769px) {
    position: relative;
    width: 220px;
  }
`

const HeaderLogo = styled.div`
  text-align: center;

  @media (min-width: 769px) {
    margin: 0;
  }

  img {
    cursor: pointer;
    width: 100%;
  }
`

const DesktopMenuHolder = styled.div`
  width: 100vw;
  display: none;

  @media (min-width: 769px) {
    position: relative;
    max-width: 980px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 auto;
  }
`

const MobileMenuButton = styled.div`
  position: absolute;
  top: 28px;
  right: 28px;
  height: 12px;
  width: 20px;
  cursor: pointer;
  z-index: 200;

  @keyframes menuAnimationFirst {
    0% {
      top: 0;
    }
    50% {
      top: 6px;
      transform: rotate(0deg);
    }
    100% {
      top: 6px;
      transform: rotate(45deg);
    }
  }

  @keyframes menuAnimationLast {
    0% {
      top: 12px;
    }
    50% {
      top: 6px;
      transform: rotate(0deg);
    }
    100% {
      top: 6px;
      transform: rotate(-45deg);
    }
  }

  @keyframes menuAnimationMiddle {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  > div {
    background-color: ${({ open, bg }: { open: boolean; bg: string }) =>
      open ? colors.white : bg};
    height: 1px;
    width: 100%;
    position: absolute;

    &.first {
      top: 0px;
    }

    &.middle {
      top: 6px;
    }

    &.last {
      top: 12px;
    }

    ${({ open }: { open: boolean }) =>
      open
        ? `
      &.first {
        animation-name: menuAnimationFirst;
        animation-iteration-count: 1;
        animation-duration: 0.4s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
      }
      &.middle {
        animation-name: menuAnimationMiddle;
        animation-iteration-count: 1;
        animation-duration: 0.1s;
        animation-delay: 0.2s;
        animation-fill-mode: forwards;
      }
      &.last {
        animation-name: menuAnimationLast;
        animation-iteration-count: 1;
        animation-duration: 0.4s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
      }
    `
        : ``}
  }
`

const StyledMobileMenu = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: ${({ open }: { open: boolean }) => (open ? 0 : '100vw')};
  background-color: ${colors.green};
  color: ${colors.white};
  transition: left 0.4s ease-in-out;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Header
