import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import {
  SubHeader,
  Ingress,
  Payoff,
  PageWrapper,
  ButtonSmall,
} from '../components/Utils'
import { colors } from '../variables'

import { UIStore } from '../stores/UIStore'
import { Container, Row, Col, Hidden } from 'react-grid-system'
import { useHistory } from 'react-router-dom'

import axios from 'axios'
import { useTranslation, withTranslation } from 'react-i18next'

const Career = observer(() => {
  const [jobs, setJobs] = useState([])

  useEffect(() => {
    let arrJobs = [] as any

    const axiosInstance = axios.create({
      baseURL: 'https://api.teamtailor.com/v1',
      headers: {
        Authorization: 'Bearer gHg9ADEP6OsBNFGgJEns7mDo3_rXf5n2SaUVMxVU',
        'X-Api-Version': '20201130',
        Accept: 'application/vnd.api+json',
      },
    })

    axiosInstance
      .get('/jobs', {
        params: { 'filter%5Bstatus%5D': 'published' },
      })
      .then((response) => {
        response.data.data.map((job: any) => arrJobs.push(job))
        setJobs(arrJobs)
      })
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    UIStore.setBackgroundShade(colors.white)
  })

  return (
    <>
      <LandingArea />
      <PageWrapper style={{ backgroundColor: colors.green }}>
        <Container>
          <Row
            style={{ alignItems: 'center', marginTop: 120, marginBottom: 100 }}
          >
            <Hidden xs sm md>
              <Col lg={1} />
            </Hidden>
            <Col sm={12} lg={10}>
              {jobs && jobs.length > 0 && (
                <ul>
                  {jobs &&
                    jobs.map((job: any) => <Job key={job.id} job={job} />)}
                </ul>
              )}
            </Col>
            <Hidden xs sm md>
              <Col lg={1} />
            </Hidden>
          </Row>
        </Container>
      </PageWrapper>
    </>
  )
})

const Job = (thejob: any) => {
  const history = useHistory()
  const job = thejob.job
  const jobDate = new Date(job.attributes['created-at'])
  const { t } = useTranslation()

  return (
    <li>
      <Container>
        <Row>
          <Col sm={12} md={4}>
            <img
              src={job.attributes.picture.thumb}
              alt={t('Jobb')}
              style={{ width: '100%' }}
            />
          </Col>
          <Col sm={12} md={8}>
            <JobHolder>
              <SubHeader style={{ color: colors.orange }}>
                {job.attributes.title}
              </SubHeader>
              <Ingress style={{ marginTop: 0 }}>
                {t('Publicerat')}{' '}
                {`${jobDate.getFullYear()}-${(
                  '0' +
                  (jobDate.getMonth() + 1)
                ).slice(-2)}-${jobDate.getDate()}`}
              </Ingress>
              <ButtonSmall
                onClick={() => {
                  //document.location.href = job.links['careersite-job-url']
                  history.push(`/job/${job.id}`)
                  window.scrollTo(0, 0)
                }}
              >
                {t('Läs mer och ansök här')}
              </ButtonSmall>
            </JobHolder>
          </Col>
        </Row>
      </Container>
    </li>
  )
}

export const LandingArea = () => {
  const { t } = useTranslation()

  return (
    <LandingHolder>
      <Container>
        <Row>
          <Col xs={12} lg={6}>
            <h1>{t('career.header')}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Payoff
              style={{ color: colors.green, marginTop: 60, marginBottom: 90 }}
            >
              {t('career.ingress')}
            </Payoff>
          </Col>
        </Row>
      </Container>
    </LandingHolder>
  )
}

const JobHolder = styled.div`
  margin-bottom: 0;

  @media (min-width: 769px) {
    margin-bottom: 40px;
  }

  h2 {
    margin-top: 10px;
    margin-bottom: 6px;
  }

  ${Ingress} {
    margin-top: 0;
  }

  ${ButtonSmall} {
    margin-top: 0;
    margin-bottom: 60px;
  }
`

const LandingHolder = styled.div`
  padding-top: 140px;
  box-sizing: border-box;
`

export default withTranslation()(Career)
